import { DialogRef } from '@angular/cdk/dialog';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { toggleBackgroundBlur } from '../../../../helper/modal.helper';
import { DmbClient, Selectable, SelectableMapped } from '../../../../model';
import { ClientService, SessionService } from '../../../../services';
import { CardHeaderComponent } from '../../../card/header/card-header.component';
import { FilterComponent } from '../../../form';

const toSelectable = (
  all: Record<number, DmbClient>,
  allList: DmbClient[],
  parent: DmbClient | null = null,
  level = 0,
): Selectable[] =>
  allList
    .filter((c) => (parent ? c.parent === parent.id : !all[c.parent]))
    .reduce<Selectable[]>((r, c) => [...r, { c, level }, ...toSelectable(all, allList, c, level + 1)], []);

@Component({
  selector: 'dmb-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss', '../../modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCard, CardHeaderComponent, MatCardContent, NgIf, FilterComponent],
})
export class SelectClientComponent {
  @Output() closeModal = new EventEmitter<void>();
  current = this.clientService.client;
  clients: Record<number, DmbClient> = [];
  clientObservable = this.clientService.clients;
  selectables: SelectableMapped[] = [];
  selected: DmbClient | null = null;
  selectable: Observable<Selectable[]> = combineLatest([this.clientObservable, this.sessionService.client]).pipe(
    map(([all, selected]) => {
      const selectables = toSelectable(all, Object.values(all), null, 0);
      return selectables.filter((v) => v.c.id !== selected?.id);
    }),
  );

  constructor(
    private sessionService: SessionService,
    private dialogRef: DialogRef,
    private clientService: ClientService,
  ) {
    this.clientService.clients.subscribe((c) => (this.clients = c));
    this.selectable.subscribe(
      (s) =>
        (this.selectables = s.map((se) => {
          return {
            parent: se.c.parent,
            name: se.c.name,
            level: se.level,
          };
        })),
    );
    this.sessionService.client.subscribe((c) => (this.selected = c));
  }

  async select(selection: SelectableMapped) {
    const client = Object.values(this.clients).find((c) => c.name === selection['name']);
    if (!client) {
      await this.sessionService.unsetClient();
    } else {
      this.sessionService.switchClient(client as unknown as DmbClient);
      this.selected = client;
    }
    this.close();
  }

  close() {
    this.dialogRef.close();
    this.closeModal.emit();
    toggleBackgroundBlur();
  }
}
