<mat-slider [min]="min" [max]="max" [showTickMarks]="showTickmarks" color="accent">
  <input
    #slider
    [type]="type"
    matSliderThumb
    ngDefaultControl
    [value]="value"
    (change)="change(slider.value)"
    [name]="name"
  />
</mat-slider>
