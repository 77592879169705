import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, inject, LOCALE_ID, provideAppInitializer } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { appRoutes, DmbEnvironment, I18n } from '@dmb/dmb-ui';
import { TraceService } from '@sentry/angular';
import * as Sentry from '@sentry/angular';

import { provideDmbEnvironment } from './environments/provide-dmb-environment';

const provideSentry = (env: DmbEnvironment) => {
  if (env.sentryDsn) {
    Sentry.init({
      dsn: env.sentryDsn,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['prod', /^https:\/\/kontor-new-media-gmbh.sentry\.io\/api/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
    return [
      { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
      { provide: TraceService, deps: [Router] },
      provideAppInitializer(() => {
        inject(TraceService);
      }),
    ];
  }
  return [];
};

const env = provideDmbEnvironment();
export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAppInitializer(() => inject(I18n).init()),
    { provide: LOCALE_ID, useFactory: () => inject(I18n).locale },
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    ),
    { provide: DmbEnvironment, useValue: env },
    provideSentry(env),
  ],
};
