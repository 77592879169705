import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { importProvidersFrom, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { RouterModule, TitleStrategy } from '@angular/router';
import { DndModule } from '@ng-dnd/core';
import { HTML5Backend } from '@ng-dnd/multi-backend';
import { RxStomp, RxStompConfig } from '@stomp/rx-stomp';
import { appRoutes } from './dmb-ui.routing';
import { DmbIconService, DmbRxStompConfig, RxStompService } from './services';
import { DmbTitleStrategy } from './services/dmb-title.strategy';

registerLocaleData(localeDe, 'de');

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  providers: [
    { provide: RxStompConfig, useClass: DmbRxStompConfig },
    { provide: RxStomp, useClass: RxStompService },
    DmbIconService,
    importProvidersFrom(DndModule.forRoot({ backend: HTML5Backend })),
    { provide: TitleStrategy, useClass: DmbTitleStrategy },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
        floatLabel: 'always',
      },
    },
  ],
})
export class DmbUiModule {}
