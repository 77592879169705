import { forwardRef, Injectable } from '@angular/core';
import { RxStomp, RxStompConfig } from '@stomp/rx-stomp';
import { firstValueFrom } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { DmbEnvironment } from '../../dmb-environment';
import { CommsAuthService } from './comms-auth.service';

@Injectable({ providedIn: 'root', useClass: forwardRef(() => DmbRxStompConfig) })
export abstract class StompConfig extends RxStompConfig {}

@Injectable({ providedIn: 'root' })
export class DmbRxStompConfig extends StompConfig {
  constructor(
    private authService: CommsAuthService,
    private env: DmbEnvironment,
  ) {
    super();

    this.webSocketFactory = () => {
      return new WebSocket(`${env.v5Path(true)}/dmbsock`);
    };
    this.connectionTimeout = 0;

    this.reconnectDelay = 2000;
    this.heartbeatOutgoing = 30000;
    this.heartbeatIncoming = 10000;

    // this.debug = (msg): void => {
    //   console.log(new Date(), msg);
    // };

    this.beforeConnect = async (client: RxStomp) => {
      await firstValueFrom(
        this.authService.authenticated.pipe(
          filter((v) => v),
          switchMap(() => this.authService.token),
          catchError(() => 'Rx stomp connect catch error has been called'),
          tap((token) => (client.stompClient.connectHeaders['X-XSRF-TOKEN'] = token)),
          take(1),
        ),
      );
    };
  }
}
