export class DmbEnvironment {
  constructor(
    public readonly stage: 'prod' | 'dev' | 'staging' = 'dev',
    public readonly production = true,
    public readonly sentryDsn: string | null = null,
    private readonly path = '/v5',
    private win: Window = window,
  ) {}

  v5Path(isSocket = false) {
    const loc = this.win.location;
    const uri = isSocket ? (loc.protocol === 'https:' ? 'wss:' : 'ws:') : loc.protocol;
    const port = loc.port == '' ? '' : `:${loc.port}`;
    return `${uri}//` + loc.hostname + port + this.path;
  }
}
