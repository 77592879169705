import { Injectable } from '@angular/core';

function getLocalStorage(): Storage | undefined {
  try {
    return localStorage;
  } catch {
    console.log('Activate 3rd party cookies to have all features working');
    return undefined;
  }
}

@Injectable({ providedIn: 'root' })
export class StorageService {
  get localStorage(): Storage | undefined {
    return getLocalStorage();
  }
}
