import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'lazy-sprite-sheet',
  standalone: true,
  templateUrl: './lazy-sprite-sheet.component.html',
  styleUrl: './lazy-sprite-sheet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazySpriteSheetComponent {
  @Input() spriteSheet: SafeHtml | null = null;
}
