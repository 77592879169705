import { NgClass, NgIf } from '@angular/common';
import { AfterViewInit, booleanAttribute, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { smoothHeightAnimation, SvgIconComponent } from '@dmb/core';
import { pipe } from 'fp-ts/function';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dmb-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  animations: [smoothHeightAnimation],
  standalone: true,
  imports: [NgClass, NgIf, SvgIconComponent],
})
export class ExpansionPanelComponent implements OnDestroy, AfterViewInit {
  private subscription: Subscription = new Subscription();
  @Input({ transform: booleanAttribute }) collapsible = true;
  @Input({ transform: booleanAttribute }) expanded = true;
  @Input({ transform: booleanAttribute }) showIcon: boolean | null = null;
  @Input() expander: Observable<boolean> | null = null;
  @Output() isOpen = new EventEmitter<boolean>();
  toggleState = this.expanded;

  toggleExpansion() {
    if (!this.collapsible) return;
    if (this.expander) return;
    this.expanded = !this.expanded;
  }

  toggleComplete() {
    this.isOpen.emit(this.expanded);
    this.toggleState = this.expanded;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.expander)
      this.subscription.add(pipe(this.expander, distinctUntilChanged()).subscribe((v) => (this.expanded = v)));
  }
}
