import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SvgIconComponent } from '@dmb/core';

@Component({
  selector: 'dmb-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatFormField, MatInput, FormsModule, MatIconButton, NgIf, NgForOf, NgClass, SvgIconComponent],
})
export class FilterComponent<T> implements OnInit {
  @Input() listItems: T[] = [];
  @Input() filterField = 'name';
  @Input() selected: T | null = null;
  @Input() selectedIndex: number | null = null;
  @Input() allLabels = false;
  @Output() dataChange = new EventEmitter<T>();

  public filterVal = '';
  public filteredItems: T[] = [];

  ngOnInit() {
    this.filteredItems = this.listItems;
  }

  public filter(): void {
    this.filteredItems = this.listItems?.filter((n) =>
      (n as Record<string, string>)[this.filterField].toLowerCase().includes(this.filterVal.toLowerCase()),
    );
  }

  public clearFilter(): void {
    this.filterVal = '';
    this.filteredItems = this.listItems;
    this.selected = null;
    this.dataChange.emit();
  }

  public select(item: T, index: number): void {
    this.selectedIndex = index;
    this.selected = item;
    this.dataChange.emit(item);
  }
}
