import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';

@Component({
  selector: 'dmb-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: true,
  imports: [MatSlider, MatSliderThumb, ReactiveFormsModule],
})
export class SliderComponent {
  @Input() name = '';
  @Input() standalone = true;
  @Input() type = 'range';
  @Input() min = 0;
  @Input() max = 10;
  @Input() showTickmarks = true;
  @Input() value = 0;

  @Output() slideEvent = new EventEmitter<string>();

  change(value: string) {
    this.slideEvent.emit(value);
  }
}
