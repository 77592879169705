import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Command,
  CommandResponse,
  MutateOperation,
  MutationCommand,
  objectToMutationCommand,
  shareLast,
} from '@dmb/core';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { BehaviorSubject, finalize, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { anon, CommsTypeObject, decodeToAuthenticated, DmbFontSize, DmbTheme, UserProfile, WebAuth } from '../model';
import { AuthData } from '../model/user/login';
import { CommsAuthService, CommsService, LoadingService, RxStompService } from '../services';
import { FingerprintService } from '../services/fingerprint/fingerprint.service';

const apiUrl = '/api/v4/public';

@Injectable({ providedIn: 'root' })
export class UserService {
  private imageReload = new BehaviorSubject(true);
  private profile = pipe(this.comms.onMessage('/user/profile'), map(decodeToAuthenticated), shareLast());
  commandResponse = pipe(this.comms.onMessage<CommandResponse>('/user/command'), shareReplay(0));
  profileImage = this.imageReload.pipe(
    mergeMap(() =>
      this.http.get<{
        status: string;
        image: string;
      }>(`${apiUrl}/users/profile_image`),
    ),
    shareLast(),
  );

  user: Observable<UserProfile> = this.commsAuth.authenticated.pipe(
    mergeMap((isAuthenticated) => (isAuthenticated ? this.profile : of(anon))),
    shareLast(),
  );

  language = this.profile.pipe(
    map((u) => u.language),
    distinctUntilChanged(),
    shareLast(),
  );

  fontSize = pipe(
    this.profile,
    map((u) => u.preferences.fontSize),
    distinctUntilChanged(),
    shareLast(),
  );

  theme: Observable<DmbTheme> = pipe(
    this.profile,
    map((p) => p.preferences['theme'] || 'standard'),
    distinctUntilChanged(),
    shareLast(),
  );

  preferences = this.profile.pipe(map((p) => p.preferences));
  /**
   * This will only emit a user when they are authenticated.
   * WARNING! It will not emit when the user logs out or is set to null.
   * It is primarily useful as a trigger after the user is authenticated.
   *
   * @type {Observable<User>}
   */
  authenticatedUser = this.user.pipe(
    filter((u) => u != null && u.authenticated),
    distinctUntilChanged((a, b) => a.username === b.username),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public get fingerprint(): Observable<string> {
    try {
      return pipe(
        O.fromNullable(localStorage.getItem('browserFingerprint')),
        O.fold(() => from(this.fingerprintService.getFingerprint()), of), // if not in local store call service and convert both to observable
        filter((value): value is string => value !== undefined && value !== null),
      );
    } catch {
      console.log('Enable 3rd party cookies to have the full authentication feature');
      return new Observable<string>();
    }
  }

  constructor(
    private http: HttpClient,
    private commsAuth: CommsAuthService,
    private comms: CommsService,
    private fingerprintService: FingerprintService,
    private loadingService: LoadingService,
    private stompService: RxStompService,
    private loc: LocationStrategy,
  ) {}

  expectResponse(cmdId: string) {
    return pipe(
      this.commandResponse,
      filter((cmd) => cmd.command.id === cmdId),
      take(1),
    );
  }

  async reload() {
    await this.stompService.deactivate({ force: true });
    this.stompService.activate();
  }

  requestPasswordReset(userNameOrMail: string) {
    return this.http.post(`${apiUrl}/users/reset_password`, { email: userNameOrMail });
  }

  reloadProfileImage() {
    this.imageReload.next(true);
  }

  public login(payload: WebAuth, browserFingerprint?: string): Observable<AuthData> {
    const cmd: Command<WebAuth> = {
      id: uuid(),
      key: 'DMBWebUI:Login',
      path: 'users/auth',
      payload: {
        ...payload,
        browserFingerprint,
      },
    };
    const loading = (t: boolean) => () => {
      this.loadingService.setLoading(t);
      return this.loadingService.isLoading;
    };
    return pipe(
      loading(true),
      () => this.http.post<AuthData>(`${apiUrl}/${cmd.path}`, cmd),
      tap(() => this.refreshToken()),
      mergeMap((r: AuthData) => this.authenticatedUser.pipe(map(() => r))),
      finalize(loading(false)),
    );
  }

  refreshToken() {
    this.commsAuth.refreshToken();
  }

  logout() {
    this.http.get('/api/v4/public/users/logout').subscribe(() => location.assign(this.loc.getBaseHref()));
  }

  public updatePassword(password: string) {
    return this.sendUpdate({ password });
  }

  uploadProfileImage(file: File): Observable<HttpEvent<unknown>> {
    const formData = new FormData();
    formData.append('image', file);
    return pipe(
      this.commsAuth.token,
      switchMap((token) =>
        this.http.post<HttpEvent<unknown>>(`${apiUrl}/users/image`, formData, {
          headers: { 'X-XSRF-TOKEN': token },
          reportProgress: true,
          observe: 'events',
          responseType: 'json',
        }),
      ),
    );
  }

  public addComms(payload: CommsTypeObject) {
    const cmd = new MutationCommand(`DMBWebUI:ProfileEditor`, 'users/profile', [
      new MutateOperation('comms', 'add', payload),
    ]);
    this.comms.send('/command', cmd);
    return this.expectResponse(cmd.id);
  }

  public sendUpdate(obj: Record<string | symbol | number, unknown>, src = 'ProfileEditor') {
    const cmd = objectToMutationCommand(`DMBWebUI:${src}`, 'users/profile', obj);
    this.comms.send('/command', cmd);
    return this.expectResponse(cmd.id);
  }

  enableTotp(code: string) {
    this.comms.send(
      '/command',
      new MutationCommand(`DMBWebUI:ProfileEditor`, 'users/profile', [
        new MutateOperation('totpCode', 'replace', code),
      ]),
    );
  }

  setTheme(theme: DmbTheme) {
    return this.sendUpdate({ 'preferences.theme': theme });
  }

  setFontSize(fontSize: DmbFontSize) {
    return this.sendUpdate({ 'preferences.fontSize': fontSize });
  }

  deleteComms(payload: number) {
    this.comms.send(
      '/command',
      new MutationCommand(`DMBWebUI:ProfileEditor`, 'users/profile', [new MutateOperation('comms', 'remove', payload)]),
    );
  }

  public authenticateWithFingerPrint(form: FormGroup): Observable<AuthData> {
    if (!this.fingerprint) return new Observable();

    return pipe(
      this.fingerprint as Observable<string>,
      switchMap((hash: string) =>
        pipe(
          this.login(form.value, hash),
          tap(() => {
            try {
              localStorage.setItem('browserFingerprint', hash);
            } catch {
              console.log('Enable 3rd party cookies to have the full authentication feature');
            }
          }),
          take(1),
        ),
      ),
    );
  }
}
