import { getEnumKeyTypeGuard, getEnumTypeGuard } from '@dmb/core';

export const isSvgIcon = (icon?: string) => icon && (names as string[]).includes(icon);

export enum DmbIcon {
  'sort_ascending' = 'sort_ascending',
  'sort_descending' = 'sort_descending',
  'add' = 'icon_add',
  'add_item' = 'add_item',
  'add_item_invert' = 'add_item_invert',
  'add_playlist' = 'add_playlist',
  'add_text' = 'add_text',
  'album' = 'icon_rev_state',
  'album_new' = 'album_new',
  'apple' = 'apple',
  /* eslint-disable @typescript-eslint/no-duplicate-enum-values */
  'apple_music_itunes' = 'apple',
  'approved-2' = 'approved-2',
  'approved' = 'approved',
  'apps' = 'apps',
  'archive_in' = 'archive_in',
  'archive_out' = 'archive_out',
  'arrow_down' = 'arrow_down',
  'arrow_in' = 'arrow_in',
  'arrow_left' = 'arrow_left',
  'arrow_left_double' = 'arrow_left_double',
  'arrow_out' = 'arrow_out',
  'arrow_right' = 'arrow_right',
  'arrow_right_double' = 'arrow_right_double',
  'arrow_up' = 'arrow_up',
  'artist' = 'artist',
  'attention' = 'attention',
  'attention_inline' = 'attention_inline',
  'audience' = 'audience',
  'audiobook' = 'audiobook',
  'audiobook_track' = 'audiobook_track',
  'autoincluded' = 'autoincluded',
  'awards' = 'awards',
  'bell' = 'bell',
  'bell_dot' = 'bell_dot',
  'bonus_file' = 'bonus_file',
  'bulk_delivery' = 'bulk_delivery',
  'calendar' = 'calendar',
  'calendar_expired' = 'calendar_expired',
  'cancel' = 'cancel',
  'cancel_circle' = 'cancel_circle',
  'changes' = 'changes',
  'charts' = 'charts',
  'chat' = 'chat',
  'client' = 'client',
  'clock' = 'clock',
  'clone' = 'clone',
  'company' = 'company',
  'continue_later' = 'continue_later',
  'copy' = 'copy',
  'curved_arrow' = 'curved_arrow',
  'custom' = 'custom',
  'dashboard' = 'dashboard',
  'date' = 'date',
  'deezer' = 'deezer',
  'delete' = 'delete',
  'deliver' = 'deliver',
  'deliver_mass' = 'deliver_mass',
  'details' = 'details',
  'document' = 'document',
  'document_list' = 'document_list',
  'download' = 'download',
  'drag' = 'drag',
  'dropdown' = 'dropdown',
  'ebook' = 'ebook',
  'edit' = 'edit',
  'edit_inline' = 'edit_inline',
  'encoding' = 'encoding',
  'episode' = 'episode',
  'error' = 'error',
  'exclusive' = 'exclusive',
  'export_doc' = 'export_doc',
  'export_epub' = 'export_epub',
  'export_flac' = 'export_flac',
  'export_ilas' = 'export_ilas',
  'export_jpg' = 'export_jpg',
  'export_m4a' = 'export_m4a',
  'export_mov' = 'export_mov',
  'export_mp3' = 'export_mp3',
  'export_mp4' = 'export_mp4',
  'export_ogg' = 'export_ogg',
  'export_pdf' = 'export_pdf',
  'export_png' = 'export_png',
  'export_sap' = 'export_sap',
  'export_wma' = 'export_wma',
  'export_xls' = 'export_xls',
  'export_xml' = 'export_xml',
  'export_zip' = 'export_zip',
  'extract' = 'extract',
  'eye' = 'eye',
  'favorite' = 'favorite',
  'folder' = 'folder',
  'folder_open' = 'folder_open',
  'grid' = 'grid',
  'hashtag_edit' = 'hashtag_edit',
  'help' = 'help',
  'help_full' = 'help_full',
  'home' = 'home',
  'hq' = 'hq',
  'id_card' = 'id_card',
  'id_spread' = 'id_spread',
  'import' = 'import',
  'impersonate' = 'impersonate',
  'import_aac' = 'import_aac',
  'import_csv' = 'import_csv',
  'import_doc' = 'import_doc',
  'import_epub' = 'import_epub',
  'import_flac' = 'import_flac',
  'import_jpg' = 'import_jpg',
  'import_m4a' = 'import_m4a',
  'import_mov' = 'import_mov',
  'import_mp4' = 'import_mp4',
  'import_ogg' = 'import_ogg',
  'import_pdf' = 'import_pdf',
  'import_png' = 'import_png',
  'import_wav' = 'import_wav',
  'import_wma' = 'import_wma',
  'import_xls' = 'import_xls',
  'import_xml' = 'import_xml',
  'import_zip' = 'import_zip',
  'insert' = 'insert',
  'invalid' = 'invalid',
  'label' = 'label',
  'left' = 'left',
  'licence' = 'licence',
  'like' = 'like',
  'like_not' = 'like_not',
  'lock_locked' = 'lock_locked',
  'lock_unlocked' = 'lock_unlocked',
  'logo-sw' = 'logo-sw',
  'logo' = 'logo',
  'logo_white' = 'logo_white',
  'logo_playgroundmusic' = 'logo_playgroundmusic',
  'logo_playgroundmusic_main' = 'logo_playgroundmusic_main',
  'logo_phonag' = 'logo_phonag',
  'logo_phonag_main' = 'logo_phonag_main',
  'mastered_itunes' = 'mastered_itunes',
  'messages' = 'messages',
  'mono_stereo' = 'mono_stereo',
  'move' = 'move',
  'movie' = 'icon_movie',
  'movie_file' = 'movie_file',
  'musical_work' = 'musical_work',
  'track' = 'icon_music_track',
  'music_video' = 'music_video',
  'news' = 'news',
  'no_musical_work' = 'no_musical_work',
  'off' = 'off',
  'open' = 'open',
  'operator' = 'operator',
  'order_by-2' = 'order_by-2',
  'order_by' = 'order_by',
  'owner' = 'owner',
  'paused' = 'paused',
  'pdf' = 'pdf',
  'person' = 'person',
  'persons' = 'persons',
  'physical' = 'icon_physical',
  'physical_track' = 'physical',
  'play' = 'play',
  'playlist' = 'playlist',
  'podcast' = 'podcast',
  'previous_contract' = 'previous_contract',
  'process' = 'process',
  'product_pitching' = 'product_pitching',
  'product_project' = 'product_project',
  'product_ticket' = 'product_ticket',
  'publish' = 'publish',
  'publish_admin' = 'publish_admin',
  'publish_admin_paused' = 'publish_admin_paused',
  'publish_paused' = 'publish_paused',
  'publish_preorder' = 'publish_preorder',
  'refresh' = 'refresh',
  'release_reminder_off' = 'release_reminder_off',
  'release_reminder_on' = 'release_reminder_on',
  'remove' = 'remove',
  'rev_state' = 'rev_state',
  'right' = 'right',
  'ringtone' = 'ringtone',
  'rows' = 'rows',
  'royalities' = 'royalities',
  'rss' = 'rss',
  'save' = 'save',
  'save_next' = 'save_next',
  'sap' = 'sap',
  'search' = 'search',
  'search_replace' = 'search_replace',
  'search_reset' = 'search_reset',
  'season' = 'icon_season',
  'select_all' = 'select_all',
  'series' = 'series',
  'share' = 'share',
  'shopping' = 'shopping',
  'shopping_card' = 'shopping_card',
  'sox' = 'sox',
  'spotify' = 'spotify',
  'spotify_safe' = 'spotify_safe',
  'spotify_pitch' = 'spotify_pitch',
  'state_closing' = 'state_closing',
  'status' = 'status',
  'stem_bundle' = 'stem_bundle',
  'stem_track' = 'stem_track',
  'stop' = 'stop',
  'stream' = 'stream',
  'strike' = 'strike',
  'tags_intersect' = 'tags_intersect',
  'tags_sum' = 'tags_sum',
  'takedown' = 'takedown',
  'thumbsdown' = 'thumbsdown',
  'thumbsup' = 'thumbsup',
  'tickets' = 'tickets',
  'top_region' = 'top_region',
  'track_new' = 'track_new',
  'trailer_file' = 'trailer_file',
  'trend' = 'trend',
  'trend_up' = 'trend_up',
  'tri_down' = 'tri_down',
  'tri_up' = 'tri_up',
  'undo' = 'undo',
  'update' = 'update',
  'upload' = 'upload',
  'valid' = 'valid',
  'voice' = 'voice',
  'warning' = 'warning',
  'xls' = 'xls',
  'xml' = 'xml',
  'youtube' = 'youtube',
  'youtube_play' = 'youtube_play',
  'youtube_tool' = 'youtube_tool',
  'mail' = 'mail',
  'mail_all' = 'mail_all',
  'mail_open' = 'mail_open',
  'music_publish' = 'music_publish',
  'phone' = 'phone',
  'mobile' = 'mobile',
  'x' = 'x',
  'skype' = 'skype',
  'facebook' = 'facebook',
  'instagram' = 'instagram',
  'tiktok' = 'tiktok',
  'twitch' = 'twitch',
}

const names = Object.values(DmbIcon).map((n) => n);
export const isValidDmbIconName = getEnumTypeGuard(DmbIcon);
export const isValidDmbIconKey = getEnumKeyTypeGuard(DmbIcon);
