import { KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SvgIconComponent } from '@dmb/core';

type LengthError = { requiredLength: number; actualLength: number };
const standardErrors = {
  required: () => $localize`:@@Common.error.required:Required`,
  email: () => $localize`:@@Common.error.email:Email address is invalid`,
  minlength: (error: LengthError) => $localize`:@@Common.error.min-length:Minimum length is ${error.requiredLength}`,
  maxlength: (error: LengthError) => $localize`:@@Common.error.max-length:Maximum length is ${error.requiredLength}`,
};

@Component({
  selector: 'dmb-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    NgFor,
    KeyValuePipe,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    MatInputModule,
    SvgIconComponent,
  ],
  standalone: true,
})
export class InputComponent {
  @Input() control: FormControl = new FormControl();
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() format = '';
  @Input() required = false;
  @Input() showAsterisk = true;
  @Input() icon = '';
  @Input() validationType = '';
  @Input() showErrors = true;
  @Input() iconPlacement = '';
  @Input() autoComplete: MatAutocomplete | null = null;
  @Output() blurred = new EventEmitter();

  @ViewChild('theInput') theInput: ElementRef<HTMLInputElement> | null = null;

  // To disable a field, the forms component FormControl
  // should have { value: '', disabled: true }
  // The dmb-input e.g.  [disabled]="webAuthFormGroup.controls['user'].disabled"

  get disabled() {
    return this.control?.disabled === true;
  }

  nonStandardErrors(errors: ValidationErrors) {
    return Object.keys(errors)
      .filter((k) => !Object.prototype.hasOwnProperty.call(standardErrors, k))
      .reduce<ValidationErrors>((r, k) => ({ ...r, [k]: errors[k] }), {});
  }

  standardErrors(errors: ValidationErrors) {
    return Object.keys(errors)
      .filter((k): k is keyof typeof standardErrors => Object.prototype.hasOwnProperty.call(standardErrors, k))
      .reduce<ValidationErrors>((r, k) => {
        return { ...r, [k]: standardErrors[k](errors[k]) };
      }, {});
  }

  focus() {
    setTimeout(() => this.theInput?.nativeElement.focus());
  }
}
